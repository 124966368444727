import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const GeoIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.625 7.49995C13.6276 8.70522 13.2404 9.87902 12.5213 10.8462L8 16.875L3.47875 10.8462C2.94938 10.131 2.59724 9.30042 2.45124 8.42268C2.30524 7.54494 2.36955 6.64508 2.63889 5.79703C2.90824 4.94898 3.37492 4.17692 4.00062 3.54427C4.62632 2.91162 5.39318 2.43644 6.23821 2.15774C7.08324 1.87905 7.98232 1.80481 8.86162 1.9411C9.74092 2.0774 10.5753 2.42035 11.2963 2.94179C12.0173 3.46323 12.6044 4.14827 13.0092 4.94065C13.414 5.73304 13.625 6.61015 13.625 7.49995ZM15.5 7.49995C15.5 9.17495 14.9513 10.72 14.025 11.9675L9.17125 18.4375L8 20L6.8275 18.4375L1.975 11.9675C1.26836 11.0142 0.797969 9.90695 0.602483 8.73658C0.406997 7.56622 0.491992 6.36617 0.850487 5.23505C1.20898 4.10392 1.83074 3.07399 2.66468 2.22989C3.49863 1.38578 4.52095 0.751595 5.64765 0.379429C6.77436 0.00726265 7.97329 -0.0922599 9.14593 0.0890384C10.3186 0.270337 11.4315 0.727282 12.3932 1.42233C13.3549 2.11738 14.138 3.03069 14.678 4.08723C15.2181 5.14376 15.4998 6.31337 15.5 7.49995ZM8 9.99995C8.66304 9.99995 9.29893 9.73656 9.76777 9.26772C10.2366 8.79888 10.5 8.16299 10.5 7.49995C10.5 6.83691 10.2366 6.20102 9.76777 5.73218C9.29893 5.26334 8.66304 4.99995 8 4.99995C7.33696 4.99995 6.70107 5.26334 6.23223 5.73218C5.76339 6.20102 5.5 6.83691 5.5 7.49995C5.5 8.16299 5.76339 8.79888 6.23223 9.26772C6.70107 9.73656 7.33696 9.99995 8 9.99995Z" fill="#DDBE90"/>
    </svg>
    
    
        
    )
}