import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const PoluchilosIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" fill="#04CF00"/>
    <path d="M23.9189 32.9396L19.5005 28.5211C19.2624 28.283 18.9395 28.1493 18.6028 28.1493C18.2661 28.1493 17.9432 28.283 17.7051 28.5211C17.467 28.7592 17.3333 29.0821 17.3333 29.4188C17.3333 29.5855 17.3661 29.7506 17.4299 29.9046C17.4937 30.0587 17.5872 30.1986 17.7051 30.3165L23.0276 35.639C23.5242 36.1356 24.3264 36.1356 24.823 35.639L38.2947 22.1672C38.5328 21.9291 38.6666 21.6062 38.6666 21.2695C38.6666 20.9328 38.5328 20.6099 38.2947 20.3718C38.0567 20.1338 37.7338 20 37.3971 20C37.0604 20 36.7374 20.1338 36.4994 20.3718L23.9189 32.9396Z" fill="white"/>
    </svg>
    
)
}