import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const EyeIcon: FC<IIcon> = ({ fill = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.72677 9.91734C4.82665 9.91734 2.47767 8.16788 1.398 5.63362C2.47767 3.09937 4.82665 1.3499 7.72677 1.3499C10.6269 1.3499 12.9759 3.09936 14.0555 5.63362C12.9759 8.16788 10.6269 9.91734 7.72677 9.91734ZM7.72677 0.149902C4.20152 0.149902 1.38819 2.35014 0.191132 5.41536C0.136322 5.55571 0.136322 5.71154 0.191132 5.85189C1.38819 8.91711 4.20152 11.1173 7.72677 11.1173C11.252 11.1173 14.0653 8.91711 15.2624 5.85189C15.3172 5.71154 15.3172 5.55571 15.2624 5.41536C14.0653 2.35014 11.252 0.149902 7.72677 0.149902ZM6.67104 4.5779C6.95104 4.2979 7.33079 4.1406 7.72677 4.1406C8.12274 4.1406 8.5025 4.2979 8.7825 4.5779C9.06249 4.85789 9.21979 5.23765 9.21979 5.63362C9.21979 6.0296 9.06249 6.40935 8.7825 6.68935C8.5025 6.96935 8.12274 7.12665 7.72677 7.12665C7.33079 7.12665 6.95104 6.96935 6.67104 6.68935C6.39105 6.40935 6.23375 6.0296 6.23375 5.63362C6.23375 5.23765 6.39105 4.85789 6.67104 4.5779ZM7.72677 2.9406C7.01253 2.9406 6.32755 3.22433 5.82251 3.72937C5.31747 4.23441 5.03375 4.91939 5.03375 5.63362C5.03375 6.34786 5.31747 7.03284 5.82251 7.53788C6.32755 8.04292 7.01253 8.32665 7.72677 8.32665C8.441 8.32665 9.12598 8.04292 9.63102 7.53788C10.1361 7.03284 10.4198 6.34786 10.4198 5.63362C10.4198 4.91939 10.1361 4.23441 9.63102 3.72937C9.12598 3.22433 8.441 2.9406 7.72677 2.9406Z" fill={fill}/>
    </svg>
    
    )
}
