
import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const KorzinaIcon: FC<IIcon> = ({ stroke = "#FF3D00", fill= 'white', size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.666504" width="20" height="20" rx="4" fill={fill}/>
    <path d="M5.6665 6.66675H15.6665M8.58317 6.66675V6.25008C8.58317 5.69755 8.80266 5.16764 9.19336 4.77694C9.58407 4.38624 10.114 4.16675 10.6665 4.16675C11.219 4.16675 11.7489 4.38624 12.1396 4.77694C12.5303 5.16764 12.7498 5.69755 12.7498 6.25008V6.66675M6.9165 6.66675H14.4165V14.1667C14.4165 14.3878 14.3287 14.5997 14.1724 14.756C14.0161 14.9123 13.8042 15.0001 13.5832 15.0001H7.74984C7.52882 15.0001 7.31686 14.9123 7.16058 14.756C7.0043 14.5997 6.9165 14.3878 6.9165 14.1667V6.66675Z" stroke={stroke} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    
)
}