import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const FotikIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3335 14.5C11.5835 14.5 12.6462 14.0623 13.5215 13.187C14.3968 12.3117 14.8342 11.2493 14.8335 10C14.8335 8.75 14.3958 7.68733 13.5205 6.812C12.6452 5.93667 11.5828 5.49933 10.3335 5.5C9.0835 5.5 8.02083 5.93767 7.1455 6.813C6.27016 7.68833 5.83283 8.75067 5.8335 10C5.8335 11.25 6.27116 12.3127 7.1465 13.188C8.02183 14.0633 9.08416 14.5007 10.3335 14.5ZM10.3335 12.5C9.6335 12.5 9.04183 12.2583 8.5585 11.775C8.07517 11.2917 7.8335 10.7 7.8335 10C7.8335 9.3 8.07517 8.70833 8.5585 8.225C9.04183 7.74167 9.6335 7.5 10.3335 7.5C11.0335 7.5 11.6252 7.74167 12.1085 8.225C12.5918 8.70833 12.8335 9.3 12.8335 10C12.8335 10.7 12.5918 11.2917 12.1085 11.775C11.6252 12.2583 11.0335 12.5 10.3335 12.5ZM2.3335 18C1.7835 18 1.3125 17.804 0.920498 17.412C0.528498 17.02 0.332831 16.5493 0.333498 16V4C0.333498 3.45 0.529498 2.979 0.921498 2.587C1.3135 2.195 1.78416 1.99933 2.3335 2H5.4835L7.3335 0H13.3335L15.1835 2H18.3335C18.8835 2 19.3545 2.196 19.7465 2.588C20.1385 2.98 20.3342 3.45067 20.3335 4V16C20.3335 16.55 20.1375 17.021 19.7455 17.413C19.3535 17.805 18.8828 18.0007 18.3335 18H2.3335ZM18.3335 16V4H14.2835L12.4585 2H8.2085L6.3835 4H2.3335V16H18.3335Z" fill="#DDBE90"/>
    </svg>    
    )
}