import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const OtmenaIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" fill="#FF3D00"/>
    <path d="M29.9959 28L35.5824 22.4135C35.8475 22.1488 35.9967 21.7897 35.997 21.415C35.9973 21.0404 35.8488 20.681 35.5841 20.4159C35.3195 20.1507 34.9603 20.0016 34.5857 20.0012C34.2111 20.0009 33.8517 20.1494 33.5865 20.4141L28 26.0006L22.4135 20.4141C22.1483 20.149 21.7887 20 21.4138 20C21.0388 20 20.6792 20.149 20.4141 20.4141C20.149 20.6792 20 21.0388 20 21.4138C20 21.7887 20.149 22.1483 20.4141 22.4135L26.0006 28L20.4141 33.5865C20.149 33.8517 20 34.2113 20 34.5862C20 34.9612 20.149 35.3208 20.4141 35.5859C20.6792 35.851 21.0388 36 21.4138 36C21.7887 36 22.1483 35.851 22.4135 35.5859L28 29.9994L33.5865 35.5859C33.8517 35.851 34.2113 36 34.5862 36C34.9612 36 35.3208 35.851 35.5859 35.5859C35.851 35.3208 36 34.9612 36 34.5862C36 34.2113 35.851 33.8517 35.5859 33.5865L29.9959 28Z" fill="white"/>
    </svg>
)
}